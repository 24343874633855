<template>
  <OModal v-model="isActive" class="ow-contact-form-modal">
    <div class="ow-contact-form">
      <div class="ow-contact-form-header">
        <div class="ow-contact-form-header-logo-wrapper">
          <img src="/images/OxiaLogoAllWhite.png" alt="Icon" class="ow-contact-form-header-logo" />
        </div>
        <h3 class="ow-contact-form-header-title ow-heading-3">Contact us</h3>
        <button class="ow-contact-form-close os-icon icon-x" @click="closeContactForm()"></button>
      </div>
      <div class="ow-contact-form-body oxia-theme-default os-grid has-12-rows">
        <InputText label="Full name" class="os-grid-cell cols-6" isRequired labelClass="text-left" v-model="fullName"/>
        <InputText label="Subject" class="os-grid-cell cols-6" isRequired labelClass="text-left" :isDisabled="isSubjectDisabled" v-model="messageSubject"/>
        <InputText label="Email" class="os-grid-cell cols-6" isRequired labelClass="text-left" v-model="email"/>
        <div class="ow-contact-form-textarea-wrapper os-grid-cell cols-6 rows-6">
          <label for="message-textarea" class="ow-contact-form-textarea-label">Message</label>
          <textarea id="message-textarea" class="ow-contact-form-textarea" :class="{ 'is-required': message.length === 0 }" v-model="message"></textarea>
        </div>
        <InputText v-if="hasPracticeNumber" label="Practice Number" class="os-grid-cell cols-6" isRequired labelClass="text-left" v-model="practiceNumber"/>
        <InputText v-if="hasHpcsaNumber" label="HPCSA Number" class="os-grid-cell cols-6" isRequired labelClass="text-left" v-model="hpcsaNumber"/>
        <div class="ow-contact-form-captcha-wrapper os-grid-cell cols-6">
          <OCaptcha v-model="isCaptchaValid" />
        </div>
      </div>
      <div class="ow-contact-form-footer oxia-theme-default">
        <button class="ow-contact-form-footer-button is-half" :disabled="isSubmitDisabled" @click="submitButtonClicked">SUBMIT</button>
      </div>
    </div>
  </OModal>
</template>

<script setup>
import { InputText, OCaptcha } from '@oxia/client';
import { ref } from 'vue';

const isActive = defineModel({ type: Boolean, default: false })

const props = defineProps({
  subject: {
    type: String,
    default: ''
  },
  customMessage: {
    type: String,
    default: ''
  },
  hasHpcsaNumber: {
    type: Boolean,
    default: false
  },
  hasPracticeNumber: {
    type: Boolean,
    default: false
  },
  isSubjectDisabled: {
    type: Boolean,
    default: false
  }
})

// const $toast = useToast();

const fullName = ref('');
const email = ref('');
const messageSubject = ref('');
const message = ref('');
const practiceNumber = ref('');
const hpcsaNumber = ref('');
const isCaptchaValid = ref(false);

const isSubmitDisabled = computed(() => {
  return fullName.value === '' || email.value === '' || messageSubject.value === '' || message.value === '' || !isCaptchaValid.value || (props.hasHpcsaNumber && hpcsaNumber.value === '') || (props.hasPracticeNumber && practiceNumber.value === '');;
})

const closeContactForm = () => {
  isActive.value = false;
}

const resetValues = () => {
  fullName.value = '';
  email.value = '';
  messageSubject.value = '';
  message.value = '';
  practiceNumber.value = '';
  hpcsaNumber.value = '';
}

const submitButtonClicked = async () => {
  try {  
    // console.log({ fullName: fullName.value, email: email.value, message: message.value });
    console.log(props.customMessage);
    console.log(props.subject);
    
    closeContactForm();
    resetValues();
    // $toast.show({ message: 'Your message was successfuly submitted. Carbon copy of your message will be sent to the provided email.', isCloseable: true });
  }
  catch (error) {
    // $toast.show({ message: 'An error occurred. Your message was not submitted successfuly.', isCloseable: true, className: 'is-alert' });
  }
}

watch(() => props.subject, (newVal) => {
  if (newVal) messageSubject.value = newVal;
},
{immediate: true})
</script>

<style lang='scss'>

</style>